import $ from "jquery"

window.uploadIcon = function(){
    $("#uploadImage").click()
}
window.updateResult=function (params){
    alert(params)
}
window.submit_click=function(){
    $("#submit_button").click()
}
window.fileSelected=function(x){
    let images = x.files
    if(images.length == 0) return;
    let file = images[0];
    if(file.size > 1024 * 1024) {
        alert('图片大小不能超过 1MB!');
        return;
    }
    var URL = window.URL || window.webkitURL;
    let url = URL.createObjectURL(file)
    $("#icon_image").attr("src",url)
    uploadImage(x)
}
 window.uploadImage=function(x) {
    if (x == null){
        x = document.getElementById("uploadImage")
    }
    var formData = new FormData()
    var image = x.files[0]
    var type = image.name.substring(image.name.lastIndexOf(".")).toLowerCase().replace(".","")
    var search = window.location.search;
    formData.append("image",image)
//    formData.append("isUpdate",false)
    formData.append("type",type)
    $.ajax({
        url:"/product/upload_image",
        type:"POST",
        data:formData,
        contentType:false,
        processData:false,
        success: function (data) {
            console.log(data)
//            uploadFail()
            $("#iconUrl").val(data["url"])
        },
        error: function (data) {
//            uploadFail()
            console.log(data)
        }
    })
}
