import $ from "jquery"

import md5  from 'js/md5'



window.submit_click = function(){
    const password = md5($("#user_password").val());
    $("#user_password").val(password)
    $("#submit_button").click()
}
